import React, { useContext, useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import axios from 'axios';
import MainContext from 'MainContext';
import { ThemeContext } from 'context/themeContext';
import io from 'socket.io-client';
import { getFamilyBySubdomain,getCurrencies } from 'containers/login/container/api';
import { setCurrencies } from 'utils/currencies';
import { sleep } from 'utils';
//const jwtDecode = require('jwt-decode')
import jwt_decode from 'jwt-decode';
const AuthRequired = (props) => {
  const {
    token: contextToken,
    socket,
    setSocketContext,
    notifications,
    getNotificationsContext,
    getHubNotificationsContext,
    newNotificationReceived,
    
    setTokenContext,
    profile,
    getUserProfileContext,
    getUserUploadSizeContext,
    setRolesContext,
    selectedRole,
    setFamilyCurrency,
    setFamilyType,
    roles: contextRoles,
    setSelectedHUbModeId,
    setSelectedHUbModeType,
    selectedHUbModeId,
  } = useContext(MainContext).login;
  const { theme, toggleTheme, darkThemeSelected,setDarkThemeSelected } = useContext(ThemeContext);

  let token = localStorage.getItem('token');
  
  //read token from url if present (used in generating pdf reports)
  const urlParams=new  URLSearchParams(window.location.search);
  if(urlParams.has('token')){
    token= urlParams.get('token');
    // url token is access token (not refresh so we set it as Bearer)
    axios.defaults.headers.common.Authorization = 'Bearer ' + token;
    
  }


  
  const setupSocket = () => {
    if (token && !socket) {
      const newSocket = io(process.env.REACT_APP_CHAT_SERVICE_URL, {
        query: {
          token: localStorage.getItem('token'),
        },
      });

      newSocket.on('disconnect', () => {
        setSocketContext(null);
      });

      newSocket.on('connect', () => {
        let decoded = jwt_decode(token);
        newSocket.emit('joinRoom', {
          chatroomId: decoded.id,
        });
        setSocketContext(newSocket);
      });
      newSocket.on('newNotification', (notification) => {
        newNotificationReceived(notification);
      });

    }
  };

  

  useEffect(() => {
    setupSocket();
    // getNotificationsContext()
  }, [token]);

  useEffect(() => {
    if (axios.defaults.headers.common.family) {
      if (!selectedHUbModeId) {
        getNotificationsContext();
      } else {
        getHubNotificationsContext(selectedHUbModeId);
      }
    }
  }, [axios.defaults.headers.common.family, selectedHUbModeId]);

  let authorized = false;
  if (token && token.length > 0) {
    authorized = true;
    // axios.defaults.headers.common.Authorization = 'Bearer ' + token
    let decoded = jwt_decode(token);
    if (!axios.defaults.headers.common.family) {
      // axios.defaults.headers.common.family = (!profile.defualt_family || profile.defualt_family === 'self') ? '' : profile.defualt_family
      axios.defaults.headers.common.family = decoded.cellphone
    }
    if (contextToken.length === 0) {
      setTokenContext(token);
    }
  } else {
    if (contextToken.length !== 0) {
      setTokenContext('');
    }
  }
  useEffect(async() => {
    getUserProfileContext();
    getUserUploadSizeContext();
    let currenciesList=await getCurrencies();
    setCurrencies(currenciesList);
  }, [axios.defaults.headers.common.family]);
  useEffect(() => {
    const { Authorization } = axios.defaults.headers.common;
    if (Authorization && Authorization.length > 0) {
      const AuthorizationSplit = Authorization.split(' ');
      const decoded = jwt_decode(AuthorizationSplit[1]);
      const roles = JSON.parse(decoded.roles);
      setRolesContext(roles);
    }
  }, [axios.defaults.headers.common.Authorization]);

  useEffect(() => {
      if(typeof profile.user_light_theme == 'boolean')
        setDarkThemeSelected(!profile.user_light_theme);
      
  }, [profile]);
  
  const hashEmail = async (email) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(email.toLowerCase());
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  };

  useEffect(async () => {
    async function setupClarity(){
      while(typeof window.clarity != 'function')
        await sleep(1000);
      window.clarity("set", "side", "admin");
      let family=profile.defualt_family=='self'? profile.family : profile.defualt_family;
      window.clarity("set", "userId", `${family}:${profile.cellphone}:${profile._id}`);
      window.clarity("set", "userIdShort", profile._id);
      window.clarity("set", "userCellphone", profile.cellphone);
      window.clarity("set", "family", family);
    }
    async function setupOrbital(){
      let blocklist = new Set(['5afe9a4c36548a66f8af843daaa2b68a0d662172b57adc84237487a76705844a',
  '142fc808e27a41162a055f84a90b4a29ec4f986dfae48ad687fd97e035105d96',
  'a8a02f6f66797452127262ee4baeb4b2a9a7219add8296a18b67632ff7cf47c3',
  '6c07ef4512d567a482f3117654f4d31c4ec82ba9c3c59cff8de83b38b9543dde',
  'ae0e059e143500bd2fa0924c92e8eb6a7ddb32f2775ca56da1efc7fe3098193b',
  'bd65874da922fe920060839169b2dedcf3208909c03f0ebb1e949e71acc9718c',
  'fcc59e0e450acb2a51a3e8632c87e9d35c38d62cbe97b6dd87dd49565e106673',
  '2d02972381e9577c8dc6c9926b041d6558e6bb231b056a16a6c655409607f3b7',
  '064644980bd13cdf15dd7cd4c29ca3c9d219b5a660745aad75d297e996b2e4e2',
  '8bef30452adf6fb03df062b0bcdd3e446e037fd24209dc41705475d744cda9fe',
  'fe6794564d43074fe38477bfd3e92ac289f94dac9ab5bff3fe203c08fdb2eddd',
  'd7558a862e8e8d0f890ae93e4ed78c6675591881f9d832a10fad9b0a3143663b',
  'ab339557c3ccaee5b6d38b463fb9d0eab1b2610836070503a89b1a0b880472f0',
  '66255c68212e79fa1fae73c2e34ca09df44572618cd85313f72a9f5e71e29171',
  '61e4370383c53d93f6c44bc48f5ae2c15aee6df564c264ac0c59f5b8a1485079',
  '619f8e3ed6b42ae5e5e11aeadca92e3dac30210535c6481005a6f6b5db82384d',
  '986ba6809fd5e6156961dc65bf1d78c40f3115c92fe933df59a5910680b650fd',
  'c24144c2c80b08fe57b18c9fe3f27148ee127c7254f4bcd10c6d7f122f9e8abf',
  '52c5265472f1345823b87f341ee2ba5a3b43e519bb56eff2973d563e493735cf',
  'd56d957682cd7084676fe3866a7e09639de2b15d4d13b838b1074f0242fd933d',
  'bf4c89254435fc24ff76b65cc56f5f06ae0991f6e8f2d769fab6e6908ce16b88',
  '3b1b9586e82701d5a1855c75cefef01c7156effd797c75608b0c3b920c10806d',
  '6a69addc29b1f16a15e09d29443cc23646cfc1f4e398792cb6bce11c984c9d41',
  '264db695fb698e9a2d795767d844caffd114028bf944dfed59deb17321bcff21',
  '9225fa9232f52d42de56b3ad754a35ff6ddbd9c594bbc4857cf02d6c492aa4c1',
  'c035e5aff1e48accf92e9a02e9b3a1840774a61d4735d4641cf2b3807ccc836a',
  'da0f5ce4dbc58befa2dc32ab161b880cf6681d720b955d7e91c3db853fa55ed5',
  'd1f68bd4fe8b6f272be96980f6e55b9a1ff496a34d70f3180c1f6cb45ad4aeca',
  'c70caf151cad645f563b1e1ab3c1d936ee0c82000830121e087a37f057ff5440',
  'd4e4cb725d6c71a52cda138b20dec01319487088964612ef658682d8d50cb107',
  '9499dfff57cd98057ec0fa64b7da0b3789fd20eef1c20f67935636d9a866329b',
  'ead5211dfe9626feadc7a45b1d3ee072c8cc867c8ed37ffd5e0c2daa197386b6',
  'deb7596c44d12548b47d5e249e9690f8cd39ed6ecd3e54c91667d0e3da4bf715',
  'c41164f212183fb7fb44c47793fd76418c2d18ad28624ee38f60ddbc7f73761e',
  '83ba224c804f04472c9d06efebad2f961041805054dce09f7bd68e4df8950689',
  'a5d0f0865257b73f2a922a6ad8c771f0f23ddc3d8ae1391be75de2e525d23aeb',
  'b45f63e4c4063316825d55bb840678c9694e9c2fc2dbf818e1d8e0d4495fe2fe',
  '0cd7f2b5b6bbe3c6ad6a43bc137789a5545bcbdb61391602bcfddf32d1e010f6',
  '4f1b5d367b39f34edaee51a264aa6f82d819739382774ae4a705263f684b204e',
  '9fc14f8303511d367f88ad0ac2019b04d0224ac414bc891a551379de7f165568',
  '94fe614c9cc8f6a945a44c4c882e005ed254ff550cc9050f97ccc1fa31ab4f68',
  'a07a9ea5e23b79430d4845ef5489163daff4699bc4447db65dd05cc7cd2b400f',
  '7e6ea62ad413e64265919670246b2992f4d15ca38b994c27298b63a588dd8ba8',
  '4e9c94655a35dba3079b6b46d898ec9b7213f62af2f646084951ef8b0736e1b0',
  'f981e6b50a49ab8f131e3db0abc79acc71bd4cbb87ab8f0f5465641ec5581a9a',
  '1a091333af241ca09cdb65a6da47357b5e6bd9ab7aa7f2afd4ecc01181fa024e',
  'ccf9b5da87ed2d8be56f411fc8111d78cdc2428ac2e06298972d35aa6819664f',
  '3e6c6e5856c4151007ba96d06b6a3064a9689212d4464c43a2436084d99da09f']);

      let emailHash;
      try{
        emailHash=await hashEmail(profile.cellphone.toLowerCase());}
        catch(e){console.error(e);}

      const firstOpenTime = new Date(profile.create_at).getTime();
      const currentTime = Date.now();
      
      const delayToOrbitalInvite = 1 * 24 * 60 * 60 * 1000;
      const shouldTriggerOrbital = (currentTime - firstOpenTime) >= delayToOrbitalInvite;
      if (profile.defualt_family === 'self' &&
          location.host == 'layers.app' &&
          emailHash &&
          !blocklist.has(emailHash) &&
          shouldTriggerOrbital
      ) {
        while(typeof window.orbital != 'function')
          await sleep(1000);
        // await sleep(20000);
        orbital('identify', {
          userId: profile._id, // unique
          email: profile.cellphone, // unique
          name: `${profile.first_name} ${profile.last_name}`, // (optional) used to populate the scheduling form
          greetingName: profile.first_name, // (optional) used to personalize the initial prompt
          //phone: profile.cellphone // (optional) used to populate the scheduling form
        });
        orbital('trigger', 'l621ZsGw9BjE')
      }
    }
    if(typeof profile.defualt_family == 'string'){
      Promise.all([setupClarity(),setupOrbital()]);
    }
    
  }, [profile.defualt_family]);

  useEffect(() => {
    if (selectedRole) {
      getFamilyBySubdomainFunc();
      contextRoles.map((role) => {
        if (role.family === selectedRole) {
          if (role.hubId) {
            setSelectedHUbModeId(role.hubId);
            setSelectedHUbModeType(role.hubType);
          } else {
            setSelectedHUbModeId('');
            setSelectedHUbModeType('');
          }
        }
      });
    } else {
      setSelectedHUbModeId('');
      setSelectedHUbModeType('');
    }
  }, [selectedRole]);

  const getFamilyBySubdomainFunc = async () => {
    const respone = await getFamilyBySubdomain(selectedRole);
    setFamilyCurrency(respone.currency);
    setFamilyType(respone.admin_type);
  };
  return authorized === true ? (
    profile.first_name ? (
      props.children
    ) : null
  ) : (
    <Redirect to={props.redirect} />
  );
};

export default AuthRequired;
